import {
  ICreateUpdateFormField,
  ReadCardField,
} from '../../CommonInterfaces/Form';

export const ResultReadFields: ReadCardField[] = [
  {
    title: 'Идентификатор',
    name: 'result.id',
  },
  {
    title: 'Параллель',
    name: 'result.event_class',
  },
  {
    title: 'Класс',
    name: 'result.student_class',
  },
  {
    title: 'Балл',
    name: 'result.points',
  },
  {
    title: 'Место',
    name: 'result.place',
  },
  {
    title: 'Диплом',
    name: 'result.place_type',
  },
  {
    title: 'Команда',
    name: 'result.command_name',
  },
  {
    title: 'Фамилия ученика',
    name: 'student.last_name',
  },
  {
    title: 'Имя ученика',
    name: 'student.first_name',
  },
  {
    title: 'Отчество ученика',
    name: 'student.second_name',
  },
  {
    title: 'День рождения ученика',
    name: 'student.birthday',
  },
  {
    title: 'Пол ученика',
    name: 'student.gender',
  },
  {
    title: 'Наличие гражданства',
    name: 'student.is_citizen',
  },
  {
    title: 'ОВЗ',
    name: 'student.has_disability',
  },
  {
    title: 'ОУ ученика',
    name: 'student.school.name',
  },
  {
    title: 'Район ОУ ученика',
    name: 'student.school.district.name',
  },

  {
    title: 'Пользователь',
    name: 'user.name',
  },
  {
    title: 'Мероприятие',
    name: 'event.name',
  },
  {
    title: 'Предмет',
    name: 'subject.name',
  },
  {
    title: 'Загрузка',
    name: 'upload.id',
  },
  {
    title: 'Преподаватели',
    name: 'teachers',
  },
  {
    title: 'Изменён',
    name: 'result.updated_at',
  },
];

const BaseResultUpdateFields: ICreateUpdateFormField[] = [

  {
    title: 'Предмет',
    name: 'result.subject_id',
  },
  {
    title: 'Параллель',
    name: 'result.event_class',
  },
  {
    title: 'Класс',
    name: 'result.student_class',
  },
  {
    title: 'Балл',
    name: 'result.points',
  },
  {
    title: 'Место',
    name: 'result.place',
  },
  {
    title: 'Диплом',
    name: 'result.place_type',
    type: 'place_type',
  },
  {
    title: 'Команда',
    name: 'result.command_name',
  },
  {
    title: 'Фамилия ученика',
    name: 'student.last_name',
  },
  {
    title: 'Имя ученика',
    name: 'student.first_name',
  },
  {
    title: 'Отчество ученика',
    name: 'student.second_name',
  },
  {
    title: 'День рождения ученика',
    name: 'student.birthday',
    type: 'date',
  },
  {
    title: 'Пол ученика',
    name: 'student.gender',
    type: 'gender',
  },
  {
    title: 'Наличие гражданства',
    name: 'student.is_citizen',
    type: 'student.yesNo',
  },
  {
    title: 'ОВЗ',
    name: 'student.has_disability',
    type: 'student.yesNo',
  },
  {
    title: 'Район ОУ ученика',
    name: 'student.school.district.id',
    type: 'district',
    child: 'student.school.id',
  },
  {
    title: 'ОУ ученика',
    name: 'student.school.id',
    type: 'district_school',
    parent: 'student.school.district.id',
  },
  {
    title: 'Преподаватель №1',
    type: 'caption',
  },
  {
    title: 'Фамилия',
    name: 'teachers.0.last_name',
  },
  {
    title: 'Имя',
    name: 'teachers.0.first_name',
  },
  {
    title: 'Отчество',
    name: 'teachers.0.second_name',
  },
  {
    title: 'Район ОУ',
    name: 'teachers.0.school.district.id',
    type: 'district',
    child: 'teachers.0.school.id',
  },
  {
    title: 'ОУ',
    name: 'teachers.0.school.id',
    type: 'district_school',
    parent: 'teachers.0.school.district.id',
  },
  {
    title: 'Преподаватель №2',
    type: 'caption',
  },
  {
    title: 'Фамилия',
    name: 'teachers.1.last_name',
  },
  {
    title: 'Имя',
    name: 'teachers.1.first_name',
  },
  {
    title: 'Отчество',
    name: 'teachers.1.second_name',
  },
  {
    title: 'Район ОУ',
    name: 'teachers.1.school.district.id',
    type: 'district',
    child: 'teachers.1.school.id',
  },
  {
    title: 'ОУ',
    name: 'teachers.1.school.id',
    type: 'district_school',
    parent: 'teachers.1.school.district.id',
  },
];

export const ResultUpdateFields: ICreateUpdateFormField[] = [
  {
    title: 'Мероприятие',
    name: 'result.event_id',
  },
  ...BaseResultUpdateFields,
];
export const ResultInEventUpdateFields: ICreateUpdateFormField[] = [
  ...BaseResultUpdateFields,
];
